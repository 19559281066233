import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import NewsItem from './NewsItem'

class News extends Component {
  render() {
    var query = graphql`
      query {
        allWordpressPost(limit: 3) {
          edges {
            node {
              title
              content
              slug
              excerpt
            }
          }
        }
      }
    `
    return (
      <div class="container last-posts">
        <div class="row">
          <StaticQuery
            query={query}
            render={data =>
              data.allWordpressPost.edges.map(item => {
                if (item.node.slug !== 'test') {
                  return <NewsItem data={item.node} />
                }
              })
            }
          />
        </div>
      </div>
    )
  }
}

export default News
