import React from 'react'

export default props => (
  <div class="container quick-contact">
    <div class="row">
      <div class="col-lg-6" data-aos="fade-down-right" data-aos-duration="800">
        <h3>{props.data.acf.title}</h3>
        <a href={'mailto:' + props.data.acf.email} class="btn">
          Napisz wiadomość
        </a>
      </div>
      <div class="col-lg-6" data-aos="fade-up-left" data-aos-duration="800">
        <img
          src={props.data.acf.image.localFile.childImageSharp.fixed.src}
          alt={
            props.data.acf.image.alt_text
              ? props.data.acf.image.alt_text
              : 'MS-Meble'
          }
        />
      </div>
      <div class="col">
        <hr />
      </div>
    </div>
  </div>
)
