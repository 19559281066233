
import React, {  Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import QuickContactItem from './QuickContactItem';

class QuickContact extends Component {

    render() {
        var query = graphql`
            query {
                wordpressWpQuickcontact {
                    acf {
                      title
                      email
                      image {
                        alt_text
                        localFile {
                          childImageSharp {
                            fixed(width: 735, height: 415) {
                              src
                            }
                          }
                        }
                      }
                    }
                }
            }
        `;
        return (
            <>
                <StaticQuery 
                    query={query}
                    render={data => (
                        <QuickContactItem data={data.wordpressWpQuickcontact}/>
                    )}
                />
            </>
        );
    }

}

export default QuickContact;