import React from 'react'
import { Link } from 'gatsby'

export default props => (
  <div className="col-lg-4 post-intro">
    <Link to={'/wiadomosci/' + props.data.slug}>
      {props.data.date && <time>{props.data.date}</time>}
      <h4> {props.data.title} </h4>
      <hr />
    </Link>
    <Link className="post-excerpt" to={'/wiadomosci/' + props.data.slug}>
      <div dangerouslySetInnerHTML={{ __html: props.data.excerpt }} />
      <Link to={'/wiadomosci/' + props.data.slug} className="btn more">
        Czytaj więcej
      </Link>
    </Link>
  </div>
)
