
import React, {  Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SliderItem from './SliderItem';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Slider extends Component {

    render() {
        var settings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 4000,
            className: 'slider owl-carousel',
            dotsClass: 'owl-dots',
            slidesToShow: 1,
            slidesToScroll: 1
          };

        var query = graphql`
            query {
                allWordpressWpSlider {
                    edges {
                       node {
                            acf {
                                bounceInRight
                                bounceInLeft
                                link {
                                    post_name
                                }
                                image {
                                    localFile {
                                        childImageSharp {
                                          fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
                                            srcSet
                                          }
                                        }
                                    }
                                }
                            }  
                        }
                    } 
                }
            }
        `;
        return (
            <>
                <StaticQuery 
                    query={query}
                    render={data => (
                        <SlickSlider {...settings} >
                        
                            { data.allWordpressWpSlider.edges.map( (item) => 
                                <SliderItem key={item.node.acf.data} data={item.node} />
                            )}
                        </SlickSlider>
                    )}
                />
            </>
        );
    }

}

export default Slider;