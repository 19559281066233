import React from "react"
import { Link } from 'gatsby';
import Img from 'gatsby-image';

export default (props) => (
    <>
        <Img 
            fluid={ props.data.acf.image.localFile.childImageSharp.fluid } 
            alt={ props.data.acf.bounceInLeft + ' - ' + props.data.acf.bounceInRight } />
        <div className="caption">
            <h3 data-aos="slide-left"> { props.data.acf.bounceInRight } </h3>
            <h5 data-aos="slide-right">{ props.data.acf.bounceInLeft }</h5>
            <Link to={'portfolio/' + props.data.acf.link[0].post_name } data-aos="slide-down" className="btn"> Czytaj więcej </Link>
        </div>
    </>
);