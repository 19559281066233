import React from "react";
import { Link } from 'gatsby';

export default (props) => (
    <div class="container portfolio">
        <div class="row">
            <div class=" col-xl-4 col-lg-6">
                <div class="portfolio__box">
                    <img src={ props.data[0].featured_media.localFile.childImageSharp.big.src } alt={ props.data[0].title } />
                    <div class="portfolio__content">
                        <h3>{ props.data[0].title }</h3>
                        <h5>{ props.data[0].subtitle }</h5>
                    </div>
                    <Link to={ "portfolio/" + props.data[0].link.post_name } class="btn">Czytaj więcej</Link>
                </div>
            </div>
            <div class=" col-xl-4 col-lg-6">
                <div class="portfolio__box two">
                    <img src={ props.data[1].featured_media.localFile.childImageSharp.medium.src} alt={ props.data[1].title } />
                    <div class="portfolio__content">
                        <h3>{ props.data[1].title }</h3>
                        <h5>{ props.data[1].subtitle }</h5>
                    </div>
                    <Link to={ "portfolio/" + props.data[1].link.post_name } class="btn">Czytaj więcej</Link>
                </div>
                <div class="portfolio__box two">
                    <img src={ props.data[2].featured_media.localFile.childImageSharp.small.src } alt={ props.data[2].title } />
                    <div class="portfolio__content">
                        <h3>{ props.data[2].title }</h3>
                        <h5>{ props.data[2].subtitle }</h5>
                    </div>
                    <Link to={ "portfolio/" + props.data[2].link.post_name } class="btn">Czytaj więcej</Link>
                </div>
            </div>
            <div class=" col-xl-4 col-lg-6">
                <div class="portfolio__more">
                    <h3>Pozostałe
                        realizacje</h3>
                    <Link to="/realizacje" class="btn">przejdź do realizacji</Link>
                </div>
                <div class="portfolio__box">
                <img src={ props.data[3].featured_media.localFile.childImageSharp.medium.src } alt={ props.data[3].title } />
                    <div class="portfolio__content">
                        <h3>{ props.data[3].title }</h3>
                        <h5>{ props.data[3].subtitle }</h5>
                    </div>
                    <Link to={ "portfolio/" + props.data[3].link.post_name } class="btn">Czytaj więcej</Link>
                </div>
            </div>
        </div>
    </div>

);