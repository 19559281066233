import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Slider from '../components/slider/Slider'
import Advantages from '../components/advantages/Advantages'
import Newsletter from '../components/newsletter/Newsletter'
import Portfolio from '../components/portfolio/Portfolio'
import QuickContact from '../components/forms/quick-contact/QuickContact'
import News from '../components/news/News'
import DownloadSection from '../components/download-section/DownloadSection'
import SEO from '../components/seo'

class PageHomeTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage
    const homepage = this.props.data.wordpressSiteMetadata.home

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
          keywords={[`gatsby`, `application`, `react`]}
        />
        <Slider />
        <Advantages data={currentPage.acf.advantages} cols={4} />
        <DownloadSection data={currentPage.acf} />
        <Newsletter home={homepage} />
        <Portfolio />
        <QuickContact />
        <News />
      </Layout>
    )
  }
}

export default PageHomeTemplate

export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: { eq: 28 }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        advantages {
          text
          icon {
            wordpress_id
            alt
            url {
              localFile {
                publicURL
              }
            }
          }
        }
        header
        image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 95) {
                src
              }
            }
          }
        }
        file {
          guid {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    wordpressSiteMetadata {
      home
    }
  }
`
