import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PortfolioItems from './PortfolioItems';

class Portfolio extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var query = graphql`
            query {
                wordpressPage(wordpress_id: {eq: 28}) { 
                  acf {
                    portfolio {
                      title
                      subtitle
                      link {
                        post_name
                      }
                      featured_media {
                        localFile {
                          childImageSharp {
                              small:fixed(width: 480, height: 230, quality: 95) {
                                src
                              }
                              medium:fixed(width: 480, height: 515, quality: 95) {
                                src
                              }
                              big:fixed(width: 480, height: 774, quality: 95) {
                                src
                              }
                          }
                        }
                      }
                    }
                  }
                }
            }
        `;

        return (
            <>
                <StaticQuery 
                    query={query}
                    render={data => (
                        <PortfolioItems data={ data.wordpressPage.acf.portfolio } />
                    )}
                />
            </>
        );
    }
}

export default Portfolio;